/* eslint-disable */
import React, { useRef } from "react";
// import ReactDOM from 'react-dom';
import "./../../App.css";
// import "react-dropdown/style.css";
import Common from "../common/common";
import emailjs from '@emailjs/browser';
import PropTypes from "prop-types";
import ApiService from "../common/api";
import MODEL_DATA from "./modelData";
import TextField from "@mui/material/TextField";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FiMenu } from "react-icons/fi";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CallIcon from '@mui/icons-material/Call';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// import { IoIosCall, IoIosClose  } from "react-icons/Io";
// import { BiMap } from "react-icons/Bi";
// import { CgNotes } from "react-icons/Cg";
import img1 from "../common/images/1.jpg";
import logo from "../common/images/Logo1.png";
import img2 from "../common/images/2.jpg";
import img3 from "../common/images/3.jpg";
import map from "../common/images/map3.PNG";
import Rating from '@mui/material/Rating';
class JobSchedule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            name: "", phone: "", email: "", description: "", error: false,
            ratingData: MODEL_DATA.ratingData,
            dialogOpen: false, ratingDialogOpen: false, updateFlag: false, errorMsg: false, invalidDate: false
        };
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        // Common.showLoader();
        // ApiService.postAPI("getRatingInfo/", requestParam).then((res) => {
        //     this.setState({ ratingData: res.data });
        //     Common.hideLoader();
        // }).catch((error) => {
        //     Common.hideLoader();
        //     console.log(error);
        // });

    }

    onInputValue(e, id) {
        if (id == "name") {
            if (e.target.value.length < 20)
                this.setState({ name: e.target.value });
        } else if (id == "phone") {
            if (e.target.value.length < 20)
                this.setState({ [id]: e.target.value });
        } else if (id == "email") {
            this.setState({ error: false });
            if (e.target.value.length < 50)
                this.setState({ [id]: e.target.value });
        } else if (id == "description") {
            if (e.target.value.length < 500)
                this.setState({ [id]: e.target.value });
        }
    }

    closeMenu(flag, id) {
        this.setState({ menuOpen: flag })
        if (!flag && id != null && document.getElementById(id) != null)
            document.getElementById(id).scrollIntoView();
    }
    onSubmit(event) {
        event.preventDefault();
        var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (this.state.email.trim() == "" || !this.state.email.match(validRegex)) {
            this.setState({ error: true });
        } else {
            Common.showLoader();
            // my email template = template_6j3jt9v
            // mansi email template = template_geh4upb
            emailjs.sendForm('service_1ylpcni', 'template_geh4upb', event.target, 'AQxDjbsKFZrH6okqR')
                .then((result) => {
                    Common.hideLoader();
                    this.setState({ dialogOpen: false, name: "", phone: "", email: "", description: "", error: false });
                    Common.notify().success(["Quote Submitted Successfully"]);
                }, (error) => {
                    console.log(error.text);
                    Common.hideLoader();
                    Common.notify().error(["Unable to prcess. Please try again."]);
                });
            // var requestURL = "";
            // ApiService.postAPI(requestURL, request).then((res) => {
            //     Common.hideLoader();
            //     console.log(res.data);
            //     this.setState({ dialogOpen: false });
            //     Common.notify().success(["Information Submitted Successfully"]);

            // }).catch((error) => {
            //     Common.hideLoader();
            //     this.setState({ dialogOpen: false });
            //     Common.notify().success(["Information Submitted Successfully"]);
            //     Common.notify().error(["Failed. Please check logs."]);
            // });
        }
    }
    openDialog(flag) {
        this.setState({ dialogOpen: flag });
    }
    openRatingDialog(flag) {
        this.setState({ ratingDialogOpen: flag });
    }

    render() {
        return (
            <div>
                <div className="HeaderElement">
                    <div className="row pt-3 pb-3 fs1_5">
                        <div className="col-lg-6 col-md-12 col-sm-12 text-center">
                            <FiMenu className="iconCss fs1_5" onClick={() => this.closeMenu(true, "")} />
                            <span className="font_w_500 ml-3" style={{ fontSize: "1.5rem", color: "darkgreen" }}>"Healing Minds" | Counselor in Gurugram</span>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 text-center" style={{ position: "relative", top: "3px", fontSize: "1rem" }}>
                            <a className="mr-4" onClick={() => this.openDialog(true)}>
                                <DescriptionOutlinedIcon className="mr-1 iconCss1 fs1_2" title="Get Quote" />
                                Get Quote</a>
                            <a className="mr-4" href="tel:7206834463">
                                <CallIcon className="mr-1 iconCss1" title="Call Now" />
                                Call Now</a>
                            <a href="http://maps.google.com/?q=Jagruti Rehabilitation Center in Gurgaon" target="_blank" style={{ pointerEvents: "none" }}>
                                <LocationOnOutlinedIcon className="mr-1 iconCss1 disabledd" title="Get Directions" />
                                <span className="disabledd" >Get Directions</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="bodyElement mt-3">
                    <div className=" text-center">
                        <div className="font_w_500 mainText pl-3 pr-3">"Healing Minds" | Counselor in Gurugram</div>
                        <div className="mt-3">Online Counselling</div>
                        <div className="mt-1">Open | Monday-Friday | 10:00 AM - 6:00 PM</div>
                        <div className="mt-3 mb-1"><button type="button" className="btn btn-info pt-1 pb-1 pl-3 pr-3"
                            onClick={() => this.openDialog(true)} style={{ fontSize: "1.2rem" }}>Get Quote</button></div>
                    </div>
                    <hr className="hrClass mt-5 mb-5" id="about"></hr>
                    <div className="row ml-3 mr-3 mb-3">
                        <div className="col-12 text-center mb-3"><h5 style={{ fontFamily: "Vollkorn,serif", fontWeight: '700' }}>ABOUT ME</h5></div>
                        <div className="col-lg-12 col-md-12 col-sm-12 text-center p-4" style={{ backgroundColor: "lightgrey", borderRadius: "5px" }} >
                            {/* <div className="pb-2 text-center">Mansi, MSc in Clinical Psychology</div> */}
                            {/* {MODEL_DATA.intro} */}
                            <span style={{ fontWeight: "500" }}>Mansi</span>, MSc in Clinical Psychology from SGT University Gurugram<br />
                            Working as a Psychologist in Jagurati Rehabilitation Centre, Gurgram<br />

                            Member of campaign 'Space Safe' which deals with sad (Stress, Anxiety and Depression) first aid course<br />
                            Rorschach inkblot test course/ certificate from FORTIS HEALTH PROGRAM<br />
                            Internship - FORTIS HEALTH CARE<br />
                            <strong>Expertise Area -</strong> Stress, Depression, Anxiety, Overthinking, Career Counseling, Family & Relationship Issues
                            , Emotional Wellness, Grief/ Loss, Relationship Issues, Schizophrenia, Psychosis, Bipolar, Dementia, Anxiety, Depression and Drug Addiction<br />
                        </div>

                    </div>
                    <hr className="hrClass mt-5 mb-5" id="Testimonials"></hr>
                    <div className="row ml-2 mr-2 mb-3">
                        <div className="col-12 text-center mb-3"><h5 style={{ fontFamily: "Vollkorn,serif", fontWeight: '700' }}>TESTIMONIALS</h5></div>
                        {this.state.ratingData.slice(0, 3).map((item, index) =>
                            <div className="col-lg-4 col-md-6 col-sm-6 text-left pl-5 mt-3" key={index}>
                                <div><Rating name="read-only" value={item.rating} readOnly /> <span className="timeCss pl-3">{item.time}</span></div>
                                <div className=""> {item.comment}</div>
                                <div className="nameCss"> - {item.name}</div>
                            </div>
                        )}
                        {this.state.ratingData.length > 3 && <div className="col-12 text-right mb-3"><a className="mr-4" onClick={() => this.openRatingDialog(true)}>Read More</a></div>}
                    </div>
                    <hr className="hrClass mt-5 mb-5" id="Gallery"></hr>
                    <div className="row ml-2 mr-2 mb-3">
                        <div className="col-12 text-center mb-3"><h5 style={{ fontFamily: "Vollkorn,serif", fontWeight: '700' }}>GALLERY</h5></div>
                        <div className="col-lg-4 col-md-4 col-sm-12 text-center mt-3">
                            <img src={img1} className="imageCss" alt="image1" />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 text-center mt-3">
                            <img src={img2} className="imageCss" alt="image2" /></div>
                        <div className="col-lg-4 col-md-4 col-sm-12 text-center mt-3">
                            <img src={img3} className="imageCss" alt="image3" /></div>
                    </div>
                    <hr className="hrClass mt-5 mb-5" id="Contact"></hr>
                    <div className="row ml-2 mr-2 mb-3">
                        <div className="col-12 text-center mb-3"><h5 style={{ fontFamily: "Vollkorn,serif", fontWeight: '700' }}>CONTACT US</h5></div>

                        <div className="col-12 text-center mt-3">
                            <a href="http://maps.google.com/?q=Jagruti Rehabilitation Center in Gurgaon" target="_blank" className="curPtr" style={{ pointerEvents: "none" }}>
                                {/* <div style={{backgroundImage:"url('https://maps.googleapis.com/maps/api/staticmap?scale=1&size=1600x900&style=feature:poi.business|visibility:off&style=feature:water|visibility:simplified&style=feature:road|element:labels.icon|visibility:off&style=feature:road.highway|element:labels|saturation:-90|lightness:25&format=jpg&language=en-US&region=IN&markers=color:0x60a5a5|30.6332865,76.8005262&zoom=16&client=google-presto&signature=y4Ty1Ja-ob4Oyy3Ip9b4mFkELAs')", height: "20rem", width:"100%"}}
                                title="Map showing business location."></div> */}
                                <img src={map} className="imageCss" alt="Map showing business location" title="Map showing business location" />
                            </a>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 text-center mt-3">
                            <div><h5 style={{ fontFamily: "Vollkorn,serif", fontWeight: "bold" }}>Contact</h5></div>
                            <div className="mt-3 mb-3">
                                <button type="button" className="btn btn-info pt-1 pb-1 pl-3 pr-3" style={{ fontSize: "1rem" }}>
                                    <a className="buttonAnchor" href="tel:7206834463">Call Now</a>
                                </button>

                            </div>
                            <div>07206834463</div>
                            <div>07792972660</div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 text-center mt-3">
                            <div><h5 style={{ fontFamily: "Vollkorn,serif", fontWeight: "bold" }}>Address</h5></div>
                            <div className="mt-3 mb-3">
                                <button type="button" disabled className="btn btn-info pt-1 pb-1 pl-3 pr-3" style={{ fontSize: "1rem", backgroundColor: "grey" }} title="Coming Soon..">
                                    <a style={{ pointerEvents: "none" }} className="buttonAnchor" href="http://maps.google.com/?q=Jagruti Rehabilitation Center in Gurgaon" target="_blank">Get Directions</a>
                                </button>

                            </div>
                            <div>Office space coming soon..</div>
                            {/* <div>Jagruti Rehabilitation Center, Sector 42, Gurugram (411028)</div> */}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 text-center mt-3">
                            <div><h5 style={{ fontFamily: "Vollkorn,serif", fontWeight: "bold" }}>Business Hours</h5></div>
                            <div className="mt-3 mb-3">
                                <span style={{ fontWeight: "500" }}>Monday - Friday</span> <br />
                                10:00 AM - 6:00 PM <br />
                                {/* <span style={{ fontWeight: "500" }}>Saturday </span><br />
                                10:00 AM - 2:00 PM <br /> */}
                            </div>
                        </div>
                    </div>
                </div>

                <Menu id="simple-menu" keepMounted open={this.state.menuOpen} onClose={() => { this.closeMenu(false, "") }} >
                    <MenuItem><a onClick={() => { this.closeMenu(false, "Testimonials") }}>Testimonials</a> </MenuItem>
                    <MenuItem><a onClick={() => { this.closeMenu(false, "Gallery") }}>Gallery</a></MenuItem>
                    <MenuItem><a onClick={() => { this.closeMenu(false, "Contact") }}>Contact</a></MenuItem>
                </Menu>
                <Dialog open={this.state.dialogOpen} onClose={() => { this.openDialog(false) }}>
                    <DialogTitle className="" style={{ paddingBottom: "0px" }}>Get Quote
                        <CloseRoundedIcon className="float-right curPtr" style={{ fontSize: "1.8rem" }} title="close" onClick={() => this.openDialog(false)} />
                    </DialogTitle>
                    <DialogContent className="scrollClass">
                        <form onSubmit={this.onSubmit}>
                            <div className="mt-1">
                                <TextField fullWidth type="text" label="Name" id="Name" name="name" variant="standard"
                                    value={this.state.name} onChange={(e) => this.onInputValue(e, "name")} />
                            </div>
                            <div className="mt-1">
                                <TextField type="tel" fullWidth id="phone" name="phone" variant="standard"
                                    value={this.state.phone} onChange={(e) => this.onInputValue(e, "phone")} label="Phone Number" />
                            </div>
                            <div className="mt-1">
                                <TextField type="email" fullWidth error={this.state.error} id="email" name="email" helperText="* Valid email address required."
                                    value={this.state.email} onChange={(e) => this.onInputValue(e, "email")} label="Email*" variant="standard" />
                            </div>
                            <div className="mt-1">
                                <TextField type="text" fullWidth id="standard-basic" name="description" variant="standard"
                                    value={this.state.description} onChange={(e) => this.onInputValue(e, "description")} label="How can we help you?"
                                />
                            </div>
                            <div className="mt-3 text-center">
                                <input type="submit" value="Send" className="mt-2 pb-1 pl-4 pr-4 submitBtn" />
                            </div>
                        </form>
                    </DialogContent>
                    {/* <DialogActions className="mt-2">
                        <button type="button" className="btn btn-info pt-1 pb-1 pl-3 pr-3"
                            onClick={() => this.onSubmit()} style={{ fontSize: "1rem", lineHeight: "1.4", }}>Submit</button>
                    </DialogActions> */}
                    <div className="infoText">Make sure to avoid including any sensitive information you don't want to share with this business.</div>
                </Dialog>
                <Dialog open={this.state.ratingDialogOpen} onClose={() => { this.openRatingDialog(false) }}>
                    <DialogTitle className="" style={{ paddingBottom: "0px" }}>Testimonials
                        <CloseRoundedIcon className="float-right curPtr" style={{ fontSize: "1.8rem" }} title="close" onClick={() => this.openRatingDialog(false)} />
                    </DialogTitle>
                    <div className="reviewCss">{this.state.ratingData.length} Reviews</div>
                    <DialogContent className="scrollClass" style={{ maxHeight: "25rem" }}>
                        <div >

                            {this.state.ratingData.map((item, index) =>
                                <div className="mt-3" style={{ borderBottom: "1px solid lightgrey" }} key={index}>
                                    <div><Rating name="read-only" value={item.rating} readOnly /> <span className="timeCss pl-3">{item.time}</span></div>
                                    <div className=""> {item.comment}</div>
                                    <div className="nameCssDialog"> - {item.name}</div>
                                </div>
                            )}</div>

                    </DialogContent>
                </Dialog>

            </div >
        );
    }
}
JobSchedule.propTypes = {
    history: PropTypes.any
};
export default JobSchedule;
