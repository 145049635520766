/* eslint-disable */
import React from "react";
// import PropTypes from "prop-types";
// import { withRouter } from "react-router-dom";
import HomePage from "./components/dashboards/HomePage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
// import "./sass.scss";
import Loader from "./components/loader/Loader";

class App extends React.Component {
  render() {
    let loaderMsg = [];
    loaderMsg.push(<div key={"loader"}></div>);
    return (
      // <MyProvider>
      <div className="myClass" style={{ height: "100%" }}>
        <Loader />
        {/* <Header /> */}
        <HomePage />
        {/* <Footer /> */}
        <ToastContainer />
      </div>
      );
  }
}

// App.propTypes = {
//   loader: PropTypes.any,
//   dialog: PropTypes.any,
//   userLoginDetails: PropTypes.func
// };

export default App;
